<template>
  <div>
    <div class="page-banner">
      <div
        class="container"
        style="background-image:url('https://downloads.acs.com.hk/page-headers/951_2017_sales_enquiry.jpg?5fcee43c5a7a1');"
      >
        <div class="wrapper">
          <div class="content">
            <h1>
              <a class="title" title="Sales Enquiry">General Enquiry</a>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="container sales-enquiry">
      <div class="row">
        <div class="col-md-10 offset-md-1 ">
          <div
            class="page-content"
            itemscope
            itemtype="http://schema.org/WebPage"
          >
            <form class="form-inquiry" data-parsley-validate="true">
              <input
                type="hidden"
                value="40e0f932be3a3a88adca3b721c73cd1d"
                name="csrf_token"
              />
              <h5>Contact Information</h5>
              <div class="form-group row">
                <div class="col-sm-3">Type:</div>
                <div class="col-sm-9">
                  <select class="form-control input-sm" name="country">
                    ><option value="Johor">Potential Customer</option
                    ><option value="Kedah">Exisiting Customer</option
                    ><option value="Kelantan">Potential Partner/Dealer</option
                    ><option value="Melaka">Potential Supplier</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-3">
                  Full Name<span class="acs-red">*</span>
                  :
                </div>
                <div class="col-sm-9">
                  <input
                    class="form-control input-sm"
                    id="name"
                    name="name"
                    required=""
                    data-parsley-required-message="Please specify your name."
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-3">
                  Email<span class="acs-red">*</span>
                  :
                </div>
                <div class="col-sm-9">
                  <input
                    class="form-control input-sm"
                    id="email"
                    name="email"
                    required=""
                    data-parsley-required-message="Please enter your email address."
                    data-parsley-type="email"
                    data-parsley-type-message="Please enter your email address."
                  />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3">
                  Telephone<span class="acs-red">*</span>
                  :
                </div>
                <div class="col-sm-9">
                  <input
                    class="form-control input-sm"
                    id="telephone"
                    name="telephone"
                    required=""
                    data-parsley-required-message="Please enter your telephone number."
                  />
                </div>
              </div>

              <h5>Company Information</h5>
              <div class="form-group row">
                <div class="col-sm-3">Remark</div>
                <div class="col-sm-9 readers">
                  <textarea
                    rows="4"
                    class="form-control input-sm"
                    name="steps_for_error"
                  ></textarea>
                </div>
              </div>

              <h5>Product Information</h5>

              <div class="form-group row">
                <div class="col-sm-3">Solutions:</div>
                <div class="col-sm-9">
                  <select
                    class="form-control input-sm"
                    name="country"
                    required=""
                    data-parsley-required-message="Please select your country."
                  >
                    <option value="Electronic Point of Sale System (e-POS)"
                      >Electronic Point of Sale System (e-POS)</option
                    ><option value="Procurement">Procurement</option
                    ><option value="ERP / Accounting">ERP / Accounting</option
                    ><option value="HR / Payroll system / CRM"
                      >HR / Payroll system / CRM</option
                    ><option value="Digital Marketing / Sales"
                      >Digital Marketing / Sales</option
                    >
                  </select>
                </div>
              </div>

                 <div class="form-group row">
                <div class="offset-sm-3 col-sm-9">
                  <p>
                    <small class="acs-red">* Required field</small>
                  </p>
                  <button
                    id="btn_submit"
                    data-sending="Sending"
                    data-submit="Submit"
                    type="submit"
                    class="btn btn-outline-primary"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SalesEnquiry"
};
</script>
